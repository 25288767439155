<template>
  <div>
    <notifications group="notification" position="bottom right" />

    <div class="row">
      <div class="col-md-9">
        <div class="card card-custom card-sticky" id="kt_page_sticky_card">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Add New Attribute</h3>
            </div>
            <CardToolbar
              @submitEvent="submit"
              back-text="Back to list"
              back-url="/products-attributes/list"
              button-text="Create Attribute"
            />
          </div>
          <div class="card-body">
            <!--begin::Form-->
            <form class="form">
              <div class="form-group">
                <label for="attributeTitle">Title</label>
                <input
                  v-model="attribute.title"
                  id="attributeTitle"
                  class="form-control form-control-solid"
                  placeholder="Enter Attribute title"
                />
              </div>
            </form>
            <!--end::Form-->
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-custom">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Options</h3>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label>Parent</label>
              <v-select
                class="form-control form-control--category-select"
                placeholder="Select parent attribute"
                v-model="attribute.parent"
                :options="filteredAttributes"
                :reduce="attribute => attribute['@id']"
                label="title"
              >
                <template #option="{ title, parent, productCategory }">
                  <span v-if="parent">-- {{ title }}</span>
                  <span v-else
                    ><span
                      class="label label-lg label-light-primary label-inline"
                      >{{ productCategory.title }}</span
                    >
                    {{ title }}</span
                  >
                </template>
              </v-select>
            </div>
            <div class="form-group">
              <label>Related to</label>
              <v-select
                class="form-control form-control--category-select"
                placeholder="Select category"
                v-model="attribute.productCategory"
                :options="filteredCategories"
                :reduce="category => category['@id']"
                label="title"
              >
                <template #option="{ title, parent }">
                  <span v-if="parent">-- {{ title }}</span>
                  <span v-else>{{ title }}</span>
                </template>
              </v-select>
            </div>
            <div class="form-group">
              <label>Position</label>
              <input
                type="number"
                v-model.number="attribute.position"
                class="form-control form-control-sm form-control-solid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import ApiService from '@/core/services/api.service'
import vSelect from 'vue-select'

export default {
  name: 'NewProductCategory',
  components: {
    CardToolbar,
    vSelect
  },
  data() {
    return {
      attribute: {
        title: null,
        parent: null,
        productCategory: null,
        position: 1
      },
      attributes: [],
      parentAttributes: [],
      childrenAttributes: [],
      filteredAttributes: [],
      parentCategories: [],
      childrenCategories: [],
      filteredCategories: []
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'All Attributes', route: '/categories/list' },
      { title: 'Add New' }
    ])

    this.getAttributes('/attributes', '?itemsPerPage=300').then(({ data }) => {
      this.attributes = [...data['hydra:member']]

      this.parentAttributes = this.attributes.filter(
        attribute => attribute.parent === undefined
      )
      this.childrenAttributes = this.attributes.filter(
        attribute => attribute.parent !== undefined
      )

      this.parentAttributes.forEach(parentAttribute => {
        this.filteredAttributes.push(parentAttribute)
        this.childrenAttributes.forEach(childrenAttribute => {
          if (childrenAttribute.root['@id'] === parentAttribute.root) {
            this.filteredAttributes.push(childrenAttribute)
          }
        })
      })
    })

    this.getCategories(
      '/product_categories',
      '?order[createdAt]=desc&itemsPerPage=90'
    ).then(({ data }) => {
      this.categories = [...data['hydra:member']]

      this.parentCategories = this.categories.filter(
        category => category.parent === undefined
      )
      this.childrenCategories = this.categories.filter(
        category => category.parent !== undefined
      )

      this.parentCategories.forEach(parentCategory => {
        this.filteredCategories.push(parentCategory)
        this.childrenCategories.forEach(childrenCategory => {
          if (childrenCategory.root['@id'] === parentCategory.root) {
            this.filteredCategories.push(childrenCategory)
          }
        })
      })
    })
  },
  methods: {
    async submit() {
      // console.log(this.attribute)
      this.postAttribute(this.attribute)
        .then(() => {
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Attribute success added'
          })
          setTimeout(() => {
            this.$router.push({ name: 'products-attributes-list' })
          }, 500)
        })
        .catch(() => {
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'Attribute error'
          })
        })
    },
    async postAttribute(entity) {
      return await ApiService.post('/attributes', entity)
    },
    async getAttributes(resource, slug = '') {
      try {
        return await ApiService.get(resource, slug)
      } catch (e) {
        throw new Error(e)
      }
    },
    async getCategories(resource, slug = '') {
      try {
        return await ApiService.get(resource, slug)
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.form-control--category-select {
  height: unset !important;

  .vs__dropdown-toggle {
    padding: 0;
    border: 0;
    border-radius: 0;
  }
  .vs__dropdown-menu {
    border: 1px solid #e4e6ef;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

    /* width */
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    li {
      padding: 0.65rem 1rem;

      &:hover {
        background: #f3f6f9;
        color: #3f4254;
      }
    }
  }

  .vs__open-indicator {
  }
  .vs__dropdown-toggle {
  }
  .vs__clear {
    display: none;
  }
  .vs__selected {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.25rem 0.75rem;
    font-size: 1rem;
    margin: 0;
    position: relative;
    float: left /*rtl:right*/;
    color: #3f4254;
    background: #f3f6f9;
    border: 0;
    border-radius: 0.42rem;
  }
  .vs__no-options {
  }
  .vs__spinner {
  }
  .vs__deselect {
  }
  .vs__active {
  }
  .vs__dropdown-option--highlight {
    background: #ebedf3;
    color: #3f4254;
  }
}
</style>
